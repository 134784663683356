import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {NGXLogger} from 'ngx-logger';
import {timeActions} from '../store/time/actions';
import {settingsActions} from '../store/settings/actions';
import {accountActions} from '../store/account/actions';
import {splashScreenActions} from 'src/app/features/splash-screen/store/actions';
import {IUser} from '../types/user.interface';
import {EUserRole} from '../enums/user-role.enum';
import {employeeActions} from '../store/employees/actions';
import {alertActions} from 'src/app/features/alert/store/actions';
import {menuActions} from 'src/app/features/menu/store/actions';
import {usersActions} from '../store/users/actions';
import {selectAccount} from '../store/account/reducer';
import {isEmployee} from '../utils/util.functions';
import {absenceActions} from '../store/absence/actions';
import {UpdateService} from './update.service';

@Injectable({
  providedIn: 'root',
})
export class FlowControlService {
  store = inject(Store);
  logger = inject(NGXLogger);
  updateService = inject(UpdateService);
  account: IUser | null = null;

  constructor() {
    this.store.select(selectAccount).subscribe((account) => {
      this.account = account;
    });
  }

  appStart() {
    this.logger.info('********** App started **********');
    this.store.dispatch(timeActions.fetchTime());
    this.store.dispatch(settingsActions.fetchSettings());
    this.store.dispatch(splashScreenActions.show());
    this.store.dispatch(accountActions.autoLogin());
    this.updateService.checkUpdates();
  }

  refresh() {
    this.store.dispatch(timeActions.fetchTime());
    this.homePage();
  }

  homePage() {
    if (this.account) {
      switch (this.account.role) {
        case EUserRole.ADMINISTRATOR:
        case EUserRole.BOARD:
        case EUserRole.MANAGER:
        case EUserRole.EMPLOYEE:
        case EUserRole.TRAINEE:
          this.store.dispatch(employeeActions.fetchEmployees());
          break;
      }
    }
  }

  usersPage() {
    if (this.account) {
      switch (this.account.role) {
        case EUserRole.ADMINISTRATOR:
        case EUserRole.BOARD:
        case EUserRole.MANAGER:
        case EUserRole.EMPLOYEE:
        case EUserRole.TRAINEE:
          this.store.dispatch(usersActions.fetchUsers());
          break;
      }
      this.store.dispatch(usersActions.removeSelectedUser());
      this.store.dispatch(employeeActions.removeAlias());
    }
  }

  absencesPage() {
    this.store.dispatch(absenceActions.reset());
  }

  calendarPage(month: number) {
    this.store.dispatch(employeeActions.setCalendarMonth({payload: month}));
  }

  setCurrentAccountForEmployeeState(account: IUser) {
    if (this.account && isEmployee(this.account)) {
      this.store.dispatch(
        employeeActions.setCurrentEmployee({payload: account.id}),
      );
    }
  }

  logout() {
    this.store.dispatch(employeeActions.logout());
    this.store.dispatch(menuActions.reset());
  }
}
