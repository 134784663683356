import {createActionGroup, emptyProps, props} from '@ngrx/store';
import {EMessageType} from 'src/app/shared/enums/message-type.enum';

export const alertActions = createActionGroup({
  source: 'alert',
  events: {
    showError: props<{error: any}>(),
    ShowAlert: props<{
      message: string;
      messageType: EMessageType;
      id?: string | null;
    }>(),
    ShowAlertWithInput: props<{
      message: string;
      messageType: EMessageType;
      id?: string | null;
    }>(),
    CloseAlert: emptyProps(),
    Reset: emptyProps(),
  },
});
